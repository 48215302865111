/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* source-sans-3-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/source-sans-3-v15-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-sans-3-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/source-sans-3-v15-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-sans-3-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/source-sans-3-v15-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/open-sans-v40-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/open-sans-v40-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* maven-pro-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/maven-pro-v33-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* bebas-neue-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'BebasNeueRegular';
  src: url('/extras/fonts/bebas-neue-v14-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #7f7f7f;
  color: #7f7f7f;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #323232 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: 700;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #464646;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #464646;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: 700;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #464646;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #e73a29;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #c6c6c6;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #ea4f40;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #ea4f40;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 900px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: 700;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #f5f5f5;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(234, 79, 64, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 17px;
  font-size: 1.7rem;
  width: 100%;
  background-color: transparent;
  color: #323232;
  font-family: 'Source Sans 3', sans-serif;
  line-height: 1.3;
}
#home {
  float: left;
}
.logo {
  width: 100%;
  height: auto;
}
.service_cart {
  float: right;
  font-weight: 700;
  color: #828282;
  background: url(/images/service_cart.svg) no-repeat top;
  background-size: auto 60%;
}
.belzona {
  float: right;
  width: 200px;
  height: auto;
}
@media (max-width: 899px) {
  .belzona {
    display: none;
  }
}
.metaline {
  float: right;
  margin-top: 17px;
  width: 160px;
}
.metaline img {
  float: left;
  width: 100%;
  height: auto;
}
@media (max-width: 899px) {
  .metaline {
    width: 110px;
    margin-right: 66px;
  }
}
.shopcartside {
  position: fixed;
  top: 50vh;
  right: 0;
  z-index: 2000;
  transform: translateY(-50%);
  display: none;
}
.c1 .shopcartside {
  display: block;
}
@media (max-width: 479px) {
  .shopcartside {
    display: none !important;
  }
}
.shopcartside .cb-cart-icon-holder {
  float: left;
}
.shopcartside .cb-cart-icon-link {
  float: left;
  position: relative;
  width: 50px;
  height: 50px;
  background-size: 34px 34px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/service_cart.svg);
  background-color: #e1e1e1;
  box-sizing: border-box;
  border-right: none;
  border-radius: 3px 0 0 3px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.shopcartside .cb-cart-icon-link:hover,
.shopcartside .cb-cart-icon-link:focus {
  background-color: #d4d4d4;
}
.shopcartside .cb-cart-icon-count {
  position: absolute;
  left: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 2px 0 0;
  background-color: #ea4f40;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.53846154;
  -webkit-font-smoothing: antialiased;
}
.cb-layout1 #head {
  width: 100%;
}
video {
  float: left;
  width: 100%;
}
.banner {
  background: #fff;
  border-bottom: 1px solid #c8c8c8;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.mood {
  height: 0;
  position: relative;
  overflow: hidden;
}
#slides,
.slide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  max-width: none !important;
  height: 100% !important;
  max-height: none !important;
}
.slide .cb-landscape {
  width: 100% !important;
  min-height: 100%;
  max-width: none !important;
  height: auto !important;
  max-height: none !important;
}
#expo > .link {
  display: none;
}
.title {
  background: #e1e1e1;
}
.cb-layout1 .title {
  text-align: center;
  padding: 22px 0px 15px;
  background: #ea4f40;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
  display: block;
}
.cb-layout1 .areawrapper {
  background: #ebebeb;
}
.cb-layout1 .areawrapper--side {
  background: #e1e1e1;
}
.footerplacement {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer {
  float: left;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  background: #464646;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.2;
}
.footer a {
  color: #fff;
}
#services > .meta {
  font-weight: 700;
  display: inline-block;
  padding: 1.3em 1em;
}
#services > .meta:hover,
#services > .meta:focus {
  color: #fff;
  background: #3c3c3c;
}
#services > .meta:active {
  background: #1e1e1e;
}
.bwm {
  color: #bebebe;
}
.fn {
  font-weight: 700;
}
.service_linkedin {
  width: 30px;
  height: 30px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(/images/linkedin-white.svg) no-repeat;
}
.service_linkedin:hover,
.service_linkedin:focus {
  background-position: bottom;
}
#view .sub1 {
  display: none;
}
div.sub1 {
  opacity: 0.4;
}
.navi,
.navi > .item {
  float: left;
}
.home {
  float: left;
}
.navigation a {
  display: block;
  color: #828282;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 700;
}
.navigation a:hover,
.navigation a:focus,
.navigation a.path,
.sub1.cb-layout1 .home {
  color: #ea4f40;
  background: #fafafa;
}
.home:active,
.navigation a:active {
  background: #f0f0f0;
  color: #e62d1b;
}
#view.cb-layout3 .sub3,
#view.cb-layout5 .sub3 {
  display: none;
}
#edit.cb-layout3 .sub3,
#edit.cb-layout5 .sub3 {
  opacity: 0.4;
}
div.sub3 .menu {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.1;
  display: block;
  color: #6e6e6e;
  padding: 1.22em 0.7em;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  border: solid #dcdcdc;
  border-width: 1px 0 0;
}
div.sub3 .menu:hover,
div.sub3 .menu:focus,
div.sub3 .menu.path {
  color: #ea4f40;
  background: #ebebeb;
}
div.sub3 .menu:active {
  background: #c8c8c8;
}
.sub3 > .exit > .menu {
  border-bottom-width: 1px;
}
div.sub4 {
  display: none;
}
div.sub3 > .item.path div.sub4 {
  display: block;
}
div.sub4,
.sub4 > .item {
  width: 100%;
}
div.sub4 .menu.menu {
  font-size: 13px;
  font-size: 1.3rem;
  padding-left: 20px;
  padding-top: 0.85em;
  padding-bottom: 0.85em;
}
div.sub5 {
  display: none;
}
div.sub5 > .item {
  width: 100%;
}
div.sub5 > .item > .menu {
  font-size: 12px;
  line-height: 1.33333333;
  padding-left: 26px;
  font-weight: normal;
}
div.sub4 > .item.path div.sub5 {
  display: block;
}
h1,
.unit h2 {
  line-height: 1.2;
}
h1 {
  color: #6e6e6e;
  text-transform: uppercase;
  font-family: "BebasNeueRegular";
  font-weight: normal;
}
.cb-layout1 h1 {
  font-size: 23px;
  font-size: 2.3rem;
  color: #fff;
  text-transform: none;
  font-family: 'Open Sans', sans-serif;
}
.cb-layout1 h1 > strong {
  font-weight: 700;
}
.unit h2,
h3 {
  font-size: 36px;
  font-size: 3.6rem;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "BebasNeueRegular";
}
.unit h2 > .open,
h3 > .open {
  color: #323232;
}
.unit h2 > .open:hover,
h3 > .open:hover,
.unit h2 > .open:focus,
h3 > .open:focus {
  color: #ea4f40;
}
.text {
  font-weight: 300;
}
.text {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #ea4f40;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #ed6457;
}
.link {
  background: url('/images/link.png') no-repeat 0 0.5em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.4em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
.cb-layout1 .unit {
  background: none;
}
.cb-layout1 h2 {
  font-size: 50px;
  font-size: 5rem;
}
.cb-layout1 .show.tiny .cb-landscape,
.cb-layout1 .show.tiny .cb-portrait {
  margin: 0 auto;
}
.cb-layout1 .goto > .open {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background: #ea4f40;
  margin-left: 0;
  padding: 0.4em 2em;
  letter-spacing: 1px;
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "BebasNeueRegular";
}
.cb-layout1 .goto > .open:hover,
.cb-layout1 .goto > .open:focus {
  background: #e03929;
}
.cb-layout1 .goto > .open:active {
  background: #df2a18;
}
.cb-layout2 .seam,
.cb-layout3 .seam {
  background: #f0f0f0;
}
.open[title="Zurück"] {
  color: #fff;
  background: #464646;
  margin-left: 0;
  padding: 0.2em 2em;
  display: inline-block;
}
.open[title="Zurück"]:hover,
.open[title="Zurück"]:focus {
  background: #3c3c3c;
}
.open[title="Zurück"]:active {
  background: #1e1e1e;
}
.south .body,
.south .part:first-child {
  margin-top: 0;
}
.south h2 {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 700;
  font-size: 1em;
  text-transform: none;
}
.south strong {
  font-weight: 300;
  min-width: 3.5em;
  display: inline-block;
}
.grow {
  text-transform: none !important;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #464646;
  background-color: #fff;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.14166667em 3px;
  min-height: 2.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 12px;
  font-size: 1.2rem;
  color: #444;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #939393;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.14166667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #e73a29 #c82616 #c82616 #e73a29;
  border-radius: 2px;
  background-color: #ea4f40;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(177, 33, 19, 0.75);
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #e73a29 #c82616 #c82616 #e73a29;
  background-color: #e03929;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #e73a29 #c82616 #c82616 #e73a29;
  background-color: #df2a18;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
/******* layout-small.less 2013-1-16 *******/
@media (max-width: 899px) {
  .mood,
  .title,
  .areawrapper,
  .footer,
  .navigation {
    will-change: opacity;
    -webkit-transition: opacity 0.218s;
    -moz-transition: opacity 0.218s;
    -o-transition: opacity 0.218s;
    transition: opacity 0.218s;
  }
  .navigation {
    -webkit-transition-delay: 0.6s;
    -moz-transition-delay: 0.6s;
    -o-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }
  body.cb-toggle-target-active {
    background: #f5f5f5;
    height: 100%;
    overflow: hidden;
  }
  body.cb-toggle-target-active .mood,
  body.cb-toggle-target-active .title,
  body.cb-toggle-target-active .areawrapper,
  body.cb-toggle-target-active .footer {
    opacity: 0;
  }
}
#home {
  width: 26.25698324%;
  margin-top: 10px;
  margin-bottom: 13px;
  max-width: 125px;
}
.belzona {
  margin: 20px 50px 0 0;
}
#head {
  margin: 7px 0;
}
.cb-layout1 #head {
  margin: 0;
}
.desk {
  width: 94.70899471%;
}
.mood {
  padding-bottom: 36.24338624%;
}
h1 {
  font-size: 50px;
  font-size: 5rem;
}
.cb-mobile .footer {
  padding-bottom: 4em;
}
.bwm {
  margin-top: 10px;
}
.service_linkedin {
  display: block;
  margin: 10px 0 0 7px;
}
.navigation {
  float: left;
  text-align: center;
  width: 105.58659218%;
  margin: 0 -2.79329609%;
}
@media (max-width: 899px) {
  .navigation {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.218s;
    -moz-transition: opacity 0.218s;
    -o-transition: opacity 0.218s;
    transition: opacity 0.218s;
  }
  .cb-toggle-target-active .navigation {
    background: #f5f5f5;
    max-height: none;
    overflow: visible;
    opacity: 1;
  }
}
.home,
div.sub2,
.sub2 > .item {
  width: 100%;
}
.navigation a {
  padding: 1.35em 0;
  border-top: 1px solid #c8c8c8;
}
.togglenavigation {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  position: absolute;
  right: 2.64550265%;
  top: 8px;
  cursor: pointer;
  width: 45px;
  height: 49px;
  color: #ea4f40 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
  will-change: transform;
  -webkit-transition: transform 0.218s;
  -o-transition: transform 0.218s;
  -moz-transition: transform 0.218s;
  transition: transform 0.218s;
  z-index: 3;
}
.togglenavigation:hover > span {
  background: #ea4f40;
}
.togglenavigation:active > span {
  background: #e62d1b !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 5px;
  width: 100%;
  margin-top: 0;
  background: #6e6e6e;
}
.togglenavigation > .tline-1 {
  margin-top: -15px;
}
.togglenavigation > .tline-4 {
  margin-top: 15px;
}
.togglenavigation.cb-toggle-active > .tline-1,
.togglenavigation.cb-toggle-active > .tline-4 {
  opacity: 0;
}
.togglenavigation.cb-toggle-active > .tline-2,
.togglenavigation.cb-toggle-active > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.togglenavigation.cb-toggle-active > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.togglenavigation.cb-toggle-active > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
div.sub3,
.sub3 > .item {
  width: 100%;
}
div.sub3 {
  margin-bottom: 37px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 5.58659218%;
  margin-left: 5.58659218%;
}
.area .part,
.area > .grid table {
  margin-right: 5.58659218%;
  margin-left: 5.58659218%;
  width: 88.82681564%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 88.82681564%;
}
@media (max-width: 899px) {
  .unit h2,
  .pure .part,
  .pure .foot,
  .show {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .pure .part,
  .pure .foot,
  .show {
    width: 100% !important;
  }
}
.part.payp.tile,
.part.payp.tile .papc {
  position: relative;
}
.part.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.part.payp.tile input.numb {
  position: absolute !important;
  bottom: 0.4em;
}
.part.payp.tile input.numb.grow {
  position: absolute !important;
}
.part.payp.tile .name {
  float: none;
  width: 100%;
}
.cb-layout1 .unit {
  text-align: center;
}
@media (max-width: 899px) {
  .cb-layout1 .show.tiny .cb-landscape,
  .cb-layout1 .show.tiny .cb-portrait {
    max-width: 107px !important;
    max-height: none !important;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
#disp.cb-shoppingcart {
  left: 0%;
  margin-left: 0;
  width: 100%;
  min-width: 340px;
}
h1,
h2 {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-shop.less 2013-1-16 *******/
input.prix {
  float: left;
  margin-right: 2px;
  width: 45px;
}
.cury {
  /* was div.payp span.cury, check shop lightbox */
  margin-left: 4px;
}
.payp input.numb {
  font-size: 17px;
  font-size: 1.7rem;
  float: left;
  margin-right: 5px;
  width: 2em !important;
  text-align: right;
}
.payp .ctrl > div,
.ctrl > .payp {
  display: inline-block;
  vertical-align: top;
}
.ctrl > .payp {
  top: 0.1em;
  position: relative;
  padding: 0 0.5em;
}
.payp .name,
.payp .ctrl {
  padding-bottom: 0.5em;
}
.payp .name {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
.payp.tile,
.payp.tile .papc {
  position: relative;
}
.payp.tile .papc {
  padding-bottom: 2em;
  margin-bottom: -2em;
}
.payp.tile input.numb {
  bottom: 0.4em;
  position: absolute;
}
.payp .ctrl {
  float: right;
  text-align: right;
}
.payp input.payp {
  padding: 0;
  width: 25px;
  height: 25px;
  border: 0;
  background: url('/icons/cart.png') no-repeat;
}
.payp input.payp:hover {
  background-position: 0px -30px;
  background-color: transparent;
}
.payp input.success {
  background-position: 0px -60px;
}
.payp input.success:hover {
  background-position: 0px -90px;
}
.payp input.fade,
.payp input.fade:hover {
  background-position: 0px -120px;
}
#root div.payp textarea {
  resize: none;
}
.cb-price-comment,
.cb-price-file,
.cb-price-file input {
  float: left;
  margin-top: 0.2em;
  width: 100%;
}
.cb-price-comment > textarea {
  width: 100%;
}
.cb-price-comment > .hint {
  color: #868686;
}
#disp.shop .name,
a.agbs,
.cb-shoppingcart .cb-shop-country > label {
  float: left;
  padding-right: 1%;
}
.cb-shop-country {
  padding-bottom: 2em;
}
.cb-shop-country > label {
  width: 31%;
}
#disp .cb-shop-country > select {
  width: 68%;
}
#disp.shop textarea {
  height: inherit;
}
a.agbs {
  overflow: hidden;
  margin-bottom: 5px;
  background: url('/icon-small/pdf.gif') no-repeat 2px 4px;
  text-indent: 15px;
  text-overflow: ellipsis;
}
.cb-agb > fieldset {
  float: left;
  margin-bottom: 10px;
}
.cb-shop-tab-contents h3,
.cb-shoppingcart h3 {
  clear: both;
  margin: 14px 0 5px;
  padding: 0.2em 5px;
  border-bottom: 1px solid #aaa;
  background-color: #f5f5f5;
  color: #333;
  letter-spacing: 0.02em;
  font-size: 1.1em;
  line-height: 1.25;
}
#disp.shop .foot {
  padding: 10px 0;
  border-top: 1px solid #aaa;
}
.cb-shop-tabs {
  margin: 10px 0 -14px;
  border-bottom: 1px solid #aaa;
}
.cb-shop-tabs > li {
  display: block;
}
.cb-shop-tab {
  display: block;
  padding: 0.3em 0.4em;
  border: 1px solid #aaa;
  border-bottom-width: 0;
  background: #ddd;
  text-decoration: none;
}
.cb-shop-tab.selected {
  background-color: #f5f5f5;
}
.cb-shop-tab-contents > .cb-shop-tab-contents {
  display: none;
}
.cb-shop-tab-contents > .selected {
  display: block;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  min-height: 1px;
}
#disp.shop .name,
a.agbs {
  width: 59%;
  width: 100%;
}
#disp.shop textarea {
  width: 100%;
}
.shop .file,
.cb-shoppingcart .file,
.shop select,
.cb-shoppingcart select {
  float: left;
}
.cb-shop-exchange-rate-equal {
  float: left;
}
.cb-shop-exchange-rate {
  margin-bottom: 8px;
}
.cb-shop-currency,
.cb-shop-countries,
.cb-shop-regions {
  position: relative;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a,
.cb-shop-countries + fieldset > .name + a,
.cb-shop-regions + fieldset > .name + a {
  display: inline-block;
  text-decoration: underline;
}
.cb-shop-vat > .cury {
  float: left;
}
#disp.shop .zip {
  float: left;
  clear: left;
  margin-right: 1%;
  width: 18%;
  text-align: right;
}
#disp.shop .cb-shop-currency > select,
#disp.shop .cb-shop-countries > select,
#disp.shop .cb-shop-regions > select {
  width: 90%;
}
#disp.shop input.city {
  width: 80%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a,
.cb-shop-countries + fieldset > .name + a,
.cb-shop-regions + fieldset > .name + a {
  clear: left;
}
#cb-footnotes {
  margin: 10px 0;
  color: #6e6e6e;
  text-align: center;
}
.cb-shoppingcart table {
  border-collapse: collapse;
}
.cb-shoppingcart th,
.cb-shoppingcart td {
  padding: 4px 3px;
}
.cb-shoppingcart th {
  border-bottom: 2px solid #666;
  color: #222;
  text-align: left;
}
.cb-shoppingcart .hdpr {
  text-align: right;
}
td.hdpr,
.cb-cart-subtotal-vat-incl > .cb-cart-label,
.cb-cart-subtotal-vat-excl > .cb-cart-label {
  white-space: nowrap;
}
#items > .odd {
  background: none;
}
.cb-shoppingcart td {
  border-bottom: 1px solid #aaa;
  vertical-align: middle;
}
.cb-total-without-vat > td,
.cb-total-with-vat > td {
  font-weight: 700;
  border: 2px solid #666;
  border-style: solid none;
  background-color: #f5f5f5;
  color: #222;
}
.cost {
  text-align: right;
}
.remo {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 18px;
  height: 18px;
  margin-top: 0.22em;
  background: url('/icons/cross.png') no-repeat;
  position: relative;
  top: 5px;
}
.remo:hover,
.remo:focus {
  background-position: 0 -22px;
}
.remo:active {
  background-position: 0 -47px;
}
div.cb-payment .radio {
  margin-right: 4px;
}
div.cb-payment div.fail {
  float: none;
}
div.cb-shoppingcart div.ship {
  margin-right: 0;
}
#disp .cb-additional-information textarea {
  overflow: scroll;
  /* module shop */
  height: auto;
}
#disp fieldset.last {
  margin: 0;
}
.cb-address,
.cb-additional-information,
.cb-payment,
.cb-order,
.cb-sandbox,
.cb-agb,
.cb-shop-country {
  float: left;
  width: 100%;
}
.cb-shoppingcart .horz {
  float: left;
  width: 100%;
}
.cb-shoppingcart .cb-shipping-address-option {
  font-weight: 700;
  clear: both;
  margin: 10px 0;
  padding: 2px 0;
  border: 1px solid #6e6e6e;
  background-color: #ddd;
  color: #222;
}
div.cb-shoppingcart div.cb-address p.cb-notice {
  margin-top: 7px;
  color: #6e6e6e;
}
.cb-address > a {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.14166667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #e73a29 #c82616 #c82616 #e73a29;
  border-radius: 2px;
  background-color: #ea4f40;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(177, 33, 19, 0.75);
  line-height: 2.2em;
  float: left;
  clear: left;
  margin: 20px 0;
}
.cb-address > a:hover,
.cb-address > a:focus {
  border-color: #e73a29 #c82616 #c82616 #e73a29;
  background-color: #e03929;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.cb-address > a:active {
  outline: none;
  border-color: #e73a29 #c82616 #c82616 #e73a29;
  background-color: #df2a18;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
#disp.cb-shoppingcart input.numb {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0;
  width: 30px;
  text-align: right;
}
div.cb-shoppingcart a.agbs {
  margin-right: 10px;
}
.cb-shoppingcart fieldset > .fail {
  /* overwrites div.fail */
  float: none;
}
div.cb-shoppingcart input.cb-hybrid {
  margin-left: 5px;
}
div.cb-payment input.cb-hybrid {
  margin: 7px 0 0 0;
}
#disp.shop input.conf,
.cb-shoppingcart .next {
  float: right;
}
.cb-shoppingcart .next {
  font-weight: 700;
}
.foot > fieldset > form {
  float: left;
}
.foot > fieldset > form + form {
  float: right;
}
.cb-payment-prepay-info {
  margin: 0.7em 0;
  list-style: none;
}
.cb-payment-prepay-info-line {
  padding-left: 1em;
  min-height: 0.75em;
}
label.tiny,
label.tall {
  font-weight: 700;
}
#disp label.tiny {
  float: left;
  width: 20%;
}
#disp p.tiny {
  float: left;
  width: 80%;
}
#disp select.tiny {
  float: left;
  width: 80px !important;
}
#disp.shop .cb-shop-exchange-rate > .tiny {
  float: left;
  margin: 0 5px 0 10px;
  width: 4em !important;
  text-align: right;
}
#disp .tiny > label.name {
  width: 100%;
}
#disp.shop input.tiny {
  float: left;
  width: 5em;
  text-align: right;
}
.cb-shop-shipping-definition a {
  float: left;
}
.cb-shop-shipping-definition .remo {
  margin-top: 5px;
}
.cb-shop-shipping-definition-details {
  float: left;
  width: 100% !important;
}
.cb-shipping-definitions-tabs {
  float: left;
  clear: left;
  margin: 0 0 1em;
  width: 32%;
  background: #c6c6c6;
}
.cb-shipping-definitions-tabs > li {
  list-style: none;
}
.cb-shipping-definition-tab {
  display: block;
  padding: 0.5em 0.8em;
  border-right: 1px solid #aaa;
  text-decoration: none;
  font-size: 0.9em;
  line-height: 1.2;
}
.cb-shipping-definition-tab:hover,
.cb-shipping-definition-tab:focus {
  border-color: #c6c6c6;
  background: #f5f5f5;
}
.cb-shipping-definition-selected > .cb-shipping-definition-tab {
  border-color: #fff;
  background: #fff;
}
.cb-shipping-definitions-tab-contents {
  float: right;
  width: 67%;
  list-style: none;
}
.cb-shipping-ranges {
  width: 100%;
  table-layout: fixed;
}
.cb-shipping-range > th {
  text-align: left;
}
.cb-shipping-range-label,
.cb-shipping-range-value {
  width: 40%;
}
.cb-shipping-range-action {
  width: 20%;
}
.cb-shipping-definition-contents {
  display: none;
}
.cb-shipping-definition-selected > .cb-shipping-definition-contents {
  display: block;
}
.cb-shipping-definition-contents td {
  padding: 1px;
  vertical-align: middle;
}
@media screen and (max-width: 480px) {
  .cb-shoppingcart table,
  .cb-shoppingcart thead,
  .cb-shoppingcart tbody,
  .cb-shoppingcart th,
  .cb-shoppingcart td,
  .cb-shoppingcart tr {
    display: block;
  }
  .cb-shoppingcart thead {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
  }
  .cb-shoppingcart th {
    border: 0;
    padding: 0;
  }
  .cb-shoppingcart .cb-cart-item {
    margin-bottom: 2em;
  }
  .cb-shoppingcart td {
    *zoom: 1;
    position: relative;
    padding-left: 38%;
  }
  .cb-shoppingcart td:before,
  .cb-shoppingcart td:after {
    display: table;
    content: '';
  }
  .cb-shoppingcart td:after {
    clear: both;
  }
  .cb-shoppingcart td:before {
    font-weight: 700;
    position: absolute;
    top: 4px;
    left: 3px;
    white-space: nowrap;
    padding-right: 1em;
    width: 36%;
    text-align: left;
    content: attr(data-title);
  }
  .cb-shoppingcart #cb-footnotes {
    text-align: right;
    padding-left: 38%;
    line-height: 1.4;
  }
  .cb-cart-empty {
    display: none !important;
  }
  .cb-shoppingcart .cb-price-comment,
  .cb-shoppingcart .cb-price-file {
    float: none;
    display: block;
    padding-top: 0.2em;
    border-top: 1px dashed #ccc;
  }
  .cb-shoppingcart .cb-cart-item-action {
    padding-right: 0;
    border: 0;
  }
  .cb-shoppingcart .remo {
    *zoom: 1;
    display: block;
    float: right;
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 6em;
    background-image: none;
    color: #fff !important;
    text-indent: 0;
    font-size: 12px;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0.14166667em 1em;
    min-height: 2.2em;
    border: 1px solid;
    border-color: #d81d21;
    border-radius: 2px;
    background-color: #e64246;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(171, 23, 26, 0.75);
    line-height: 2.2em;
  }
  .cb-shoppingcart .remo:before,
  .cb-shoppingcart .remo:after {
    display: table;
    content: '';
  }
  .cb-shoppingcart .remo:after {
    clear: both;
  }
  .cb-shoppingcart .remo:hover,
  .cb-shoppingcart .remo:focus {
    border-color: #ab171a;
    background-color: #e32b30;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .cb-shoppingcart .remo:active {
    outline: none;
    border-color: #7e1113;
    background-color: #d81d21;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    color: #fff;
    text-shadow: none;
  }
  .cb-cart-subtotals {
    position: relative;
  }
  .cb-cart-subtotal-shipping {
    position: relative;
  }
  .cb-cart-subtotal-shipping > .cb-cart-label {
    padding: 0;
    position: absolute;
    top: 4px;
    left: 3px;
    white-space: nowrap;
    padding-right: 1em;
    width: 36%;
    border: 0;
  }
  .cb-cart-subtotal-shipping > .cb-cart-value {
    border: 0;
  }
  .cb-total-without-vat {
    background: #f5f5f5;
    position: relative;
  }
  .cb-total-without-vat > td {
    background: none;
  }
  .cb-total-without-vat > .cb-cart-label {
    font-weight: 700;
    position: absolute;
    top: 4px;
    left: 3px;
    margin-top: 2px;
    padding: 0;
    padding-right: 1em;
    width: 36%;
    border: 0;
    white-space: nowrap;
  }
  .cb-cart-subtotal-vat-excl,
  .cb-cart-subtotal-vat-incl {
    position: relative;
  }
  .cb-cart-subtotal-vat-excl > td,
  .cb-cart-subtotal-vat-incl > td {
    background: none;
  }
  .cb-cart-subtotal-vat-excl > .cb-cart-label,
  .cb-cart-subtotal-vat-incl > .cb-cart-label {
    position: absolute;
    top: 4px;
    left: 3px;
    padding: 0;
    padding-right: 1em;
    width: 36%;
    border: 0;
    white-space: nowrap;
  }
  .cb-cart-totals {
    display: block;
  }
  .cb-total-with-vat {
    position: relative;
    background: #f5f5f5;
  }
  .cb-total-with-vat > td {
    background: none;
  }
  .cb-total-with-vat > .cb-cart-label {
    font-weight: 700;
    position: absolute;
    top: 4px;
    left: 3px;
    padding: 0;
    margin-top: 2px;
    padding-right: 1em;
    width: 36%;
    border: 0;
    white-space: nowrap;
  }
  .cb-shoppingcart #cb-footnotes {
    float: left;
    text-align: left;
    margin-top: 0.4em;
    padding: 0;
  }
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */